<template>
  <div ref="editDocumentList" class="root">
    <el-table :data="docList" style="width: 100%">
      <el-table-column prop="originalFilename" label="文件名" width="300">
      </el-table-column>
      <el-table-column prop="userName" label="创建者" width="200"> </el-table-column>
      <el-table-column prop="createTime" label="上传时间" width="300"> </el-table-column>
      <el-table-column prop="updateTime" label="最后更新时间" width="300">
      </el-table-column>
      <el-table-column
        class-name="small-padding fixed-width"
        label="操作"
        fixed="right"
        width="160"
      >
        <template slot-scope="scope">
          <div class="operationMenuContainer">
            <a class="primaryLink" @click="openDocument(scope.row)" id="jumpLink">起草</a>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- pagination -->
    <el-pagination
      background
      layout="prev, pager, next"
      :page-count="totalCount"
      @prev-click="loadPrev"
      @next-click="loadNext"
      @current-change="currentPageChange"
    >
    </el-pagination>
    <!--  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: {},
      currentUser: {},
      docList: [],
      totalCount: 0,
      baseUrl: "https://server.moye99.com:7000",
      query: {
        page: 0,
        size: 10,
      },
      docName: "文档名称",
      docNameCharCode: [],
      openParameters: {
        fileName: "",
        reportNumber: "",
        client: "",
        projectName: "",
        evaluateValue: "",
        userId: "",
        templateId: "",
      },
      queryString: "",
    };
  },
  watch: {
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.flushDocumentList();
      },
    },
  },
  async mounted() {
    const loading = this.$loading({
      lock: true,
      text: "加载中",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.6)",
    });

    const scriptElement = document.createElement("script");
    scriptElement.src = `${this.baseUrl}/api/pageoffice.js`;
    document.head.appendChild(scriptElement);
    scriptElement.onload = () => {
      loading.close();
    };

    scriptElement.onerror = (e) => {
      console.log(e);
    };

    try {
      this.currentUser = JSON.parse(this.form.用户信息);
    } catch (e) {
      this.$message.error("用户信息解析失败");
      console.log("用户信息:", this.form.用户信息);
      loading.close();
      console.error(e);
    }
    delete this.currentUser.positions;
    delete this.currentUser.departments;
    this.currentUser.userId = this.currentUser.id;
    delete this.currentUser.id;
    const addUserRes = await this.utils.axios({
      method: "post",
      url: `${this.baseUrl}/api/user`,
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
      data: {
        ...this.currentUser,
      },
    });
  },
  methods: {
    getDateStringByTimeStamp(timeStamp) {
      if (!timeStamp) {
        return "";
      } else {
        const d = new Date(timeStamp);
        return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
      }
    },
    openDocument(row) {
      try {
        const parameters = this.openParameters;
        parameters.userId = this.currentUser.userId;
        parameters.reportNumber = this.form.报告编号;
        parameters.client = this.form.委托方;
        parameters.projectName = this.form.项目名称;
        parameters.evaluateValue = this.form.评估总值;
        parameters.templateId = row.id;
        parameters.businessNumber = this.form.业务编号;

        /**
         *
         * 坐落
         * 楼层
         * 总层
         * 权利人
         * 证件类型
         * 竣工日期
         * 建筑面积
         * 共有情况
         *
         * "location": "",
         * "obligee": "",
         * "mutualOwnershipstate": "",
         * "usage": "",
         * "area": "",
         * "totalFloors": "",
         * "floor": "",
         * "completionDate": "",
         * "certificateNumber": ""
         *
         */

        let parametersObject = {
          issueDate: this.getDateStringByTimeStamp(this.form.报告出具时间),
          valueTimePoint: this.getDateStringByTimeStamp(this.form.价值时间),
          reportGenerateDate: this.getDateStringByTimeStamp(this.form.报告制作日期),
          HousePrice: this.form.房屋单价,
          houseCertificate: {
            location: this.form.坐落,
            floor: this.form.楼层,
            totalFloors: this.form.总层,
            obligee: this.form.权利人,
            // certificateType: this.form.证件类型,
            completionDate: this.form.竣工日期,
            area: this.form.建筑面积,
            mutualOwnershipstate: this.form.共有情况,
            usage: this.form.房屋用途,
            certificateNumber: this.form.不动产权证号,
          },
          peripheral: window._FOX_GLOBAL_MAP_AROUND_LOCATIONS_,
        };

        console.log(parametersObject);

        const queryString = Object.keys(parameters)
          .map((key) => {
            return `&${key}=${encodeURI(encodeURI(parameters[key]))}`;
          })
          .join("");

        const url = `https://server.moye99.com:7000/test?a=1${queryString}`;
        console.log(url);
        POBrowser.openWindowModeless(
          url,
          "width=1200px;height=800px;",
          JSON.stringify(parametersObject)
        );
      } catch (e) {
        console.group("openDocument error");
        console.error("in [openDocument]");
        console.error(e);
        console.groupEnd();
      }
    },
    handleDelete(row) {
      this.$confirm(`确认要删除 ${row.originalFilename} 吗?`)
        .then(() => {
          this.startLoading();
          this.utils
            .axios({
              method: "delete",
              url: `${this.baseUrl}/api/fileTemplate/${row.id}?url=${row.url}&userId=${
                JSON.parse(this.form.用户信息).id
              }`,
              headers: {
                "content-type": "application/json; charset=utf-8",
              },
            })
            .then((res) => {
              this.stopLoading();
              if (res.data.code == 200) {
                this.$message.success("删除成功");
              } else {
                this.$message.warning(res.message);
              }
            })
            .finally(() => {
              this.query = {
                page: 0,
                size: 10,
              };
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    convertStringFromCharCodeArray(arr) {
      return arr.map((item) => String.fromCharCode(item)).join("");
    },
    convertToCharCodeArrayFromString(string) {
      return string.split("").map((item) => item.charCodeAt(0));
    },
    handleOpen() {
      POBrowser.openWindowModeless(
        `https://server.moye99.com/test?id=${row.id}`,
        "width=1200px;height=800px;"
      );
    },
    loadPrev() {
      --this.query.page;
    },
    loadNext() {
      ++this.query.page;
    },
    currentPageChange(newPage) {
      this.query.page = newPage - 1;
      s;
    },
    startLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中 请稍后",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.6)",
      });
    },
    stopLoading() {
      this.loading.close();
    },
    flushDocumentList() {
      this.startLoading();
      this.utils
        .axios({
          method: "get",
          url: `${this.baseUrl}/api/fileTemplate/list?page=${this.query.page}&size=${
            this.query.size
          }&userId=${JSON.parse(this.form.用户信息).id}`,
          headers: {
            "content-type": "application/json; charset=utf-8",
          },
        })
        .then(({ data }) => {
          this.stopLoading();
          this.docList = data.data.content;
          this.totalCount = data.data.totalPages;
        });
    },
  },
  props: {
    utils: {
      type: Object,
      default() {
        return {};
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style>
.operationMenuContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dangerLink {
  font-size: 14px;
  color: rgb(245, 108, 108);
  transition: all 0.1s linear;
  text-decoration: none;
}

.dangerLink:hover {
  color: rgb(240, 72, 72);
}

.primaryLink {
  font-size: 14px;
  color: rgb(132, 175, 255);
  transition: all 0.1s linear;
  text-decoration: none;
}

.primaryLink:hover {
  color: rgb(59, 62, 238);
}
</style>
